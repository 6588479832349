import React from 'react'
import { Helmet } from 'react-helmet'

const amazonLinks = [
    {title: "Adrenaline", iframeSrc: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=rickyrombo-20&marketplace=amazon&region=US&placement=B07PN29KPF&asins=B07PN29KPF&linkId=f964f7f64975e953517445139b6e053f&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff"},
    {title: "Parachute", iframeSrc: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=rickyrombo-20&marketplace=amazon&region=US&placement=B07PQ5L8GL&asins=B07PQ5L8GL&linkId=e2c852767b96718e70e4c364d012f2ae&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff" },
    {title: "A Journey in Time", iframeSrc: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=rickyrombo-20&marketplace=amazon&region=US&placement=B07JJNQP9Y&asins=B07JJNQP9Y&linkId=c8604a5c35787b01ecb26c608854a916&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066C0&bg_color=FFFFFF"},
    {title: "Patience", iframeSrc: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=rickyrombo-20&marketplace=amazon&region=US&placement=B01K52W4O2&asins=B01K52W4O2&linkId=ddbcf85e3977db4b2f98d640555f43b7&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff"},
];

export default () => {
  return (
    <article>
      <Helmet><title>Playlist to Cart</title></Helmet>
      <h2>Playlist to Cart</h2>
      <p>You use Spotify to listen to and discover music. You curate and create playlists. But you're a DJ and need to download or buy those songs. Now you need to to go one by one through your playlist and find them and buy them.</p>
      <p>This tool imports a Spotify playlist and allows you to instantly add the songs to an Amazon shopping cart.</p>
      <p>But it doesn't exist yet.</p>
      <p>Apparently, the only way to do this cleanly and programatically is to use the Amazon Product Advertising API, so I'm trying to earn my way into access.</p>
      <p>By the way, here's some music you can buy on Amazon! (As an Amazon Associate I earn from qualifying purchases.)</p>
      <div style={{display: "flex", width: "100%", justifyContent: "space-around"}}>
          {amazonLinks.map(({title, iframeSrc}, i) => 
            <div key={i} style={{width: "120px", textAlign: "center"}}>
                {<iframe title={title} style={{width:"120px", height:"240px"}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src={iframeSrc}></iframe>}
            </div>
          )}
      </div>
    </article>
  )
}
