import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { useTimeout } from '../utils/customHooks'

// import dark from '../img/dark-rickyrombo.jpg'
import profile from '../img/profile-small.jpg'

export default () => {
  const [bookingEmail, setBookingEmail] = useState(false);
  const [contactEmail, setContactEmail] = useState(false);
  
  useTimeout(() => {
    setBookingEmail(atob('Ym9va2luZ3NAcmlja3lyb21iby5jb20='));
    setContactEmail(atob('Y29udGFjdEByaWNreXJvbWJvLmNvbQ=='));
  }, 1000);

  return (
    <article>
      <Helmet><title>Contact</title></Helmet>
      <h2>Contact</h2>
      <p style={{ textAlign: 'center'}}>
        <img alt="Chilling on my friend's rooftop" title="Photo credit: Brooke Cheng" src={profile} 
            style={{ width: '200px', height: '200px', borderRadius: '50%' }}/>
      </p>
      <p>
        For booking inquiries, please email {bookingEmail ? <a href={'mailto:' + bookingEmail}>{bookingEmail}</a> : '(loading...)'}.
      </p>
      <p>
        For all other inquiries, email {contactEmail ? <a href={'mailto:' + contactEmail}>{contactEmail}</a> : '(loading...)'}. Alternatively, use the form below:
      </p>
      <form name="contact" method="POST" data-netlify="true">
        <input type="hidden" name="form-name" value="contact" />
        <p>
          <label htmlFor="name">Name:</label>
          <input id="name" type="text" name="name" />
        </p>
        <p>
          <label htmlFor="email">Email Address:</label>
          <input id="email" type="email" name="email" />
        </p>
        <p>
          <label htmlFor="subject">Subject</label>
          <input id="subject" type="text" name="subject" />
        </p>
        <p>
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message"></textarea>
        </p>
        <p>
          <input type="submit" value="Send" />
        </p>
      </form>
    </article>
  )
}