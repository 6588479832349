import React from 'react';
import { Link } from 'react-router-dom';
import headerStyles from './Header.module.css';

import logo from '../img/logo-white-large.png';

export default function Header() {
  return (
    <header className={headerStyles.header}>
      <div className={headerStyles.logoWrapper}>
        <Link to="/">
          <img alt="RickyRombo Logo" title="RickyRombo" className={headerStyles.logo} src={logo} />
        </Link>
      </div>
      <nav className={headerStyles.nav}>
        <Link to="/music">music</Link>
        <Link to="/events">events</Link>
        <Link to="/about">about</Link>
        <Link to="/contact">contact</Link>
      </nav>
    </header>
    )
}