import React from 'react'
import { Helmet } from 'react-helmet'

import headbang from '../img/headbang.gif'

export default () => (
  <article>
    <Helmet><title>404 - Page Not Found</title></Helmet>
    <h2>My Bad</h2>
    <p>Sadly, the page you're trying to visit doesn't exist. Or at least, it doesn't any more. Sorry about that <span role="img" aria-label="Sad face">😢</span></p>
    <p style={{textAlign: 'center'}}><img alt="Head banging" title="Photo credit: Steven Vukelich" src={headbang} /></p>
  </article>
)
