import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Helmet } from 'react-helmet';

// Styles and images
import appStyles from './App.module.css';

// Layout
import Header from './layout/Header';
import Footer from './layout/Footer';
import Background from './layout/Background';

// Pages
import About from './pages/about';
import Contact from './pages/contact';
import Events from './pages/events';
import Music from './pages/music';
import NotFound from './pages/404';
import PlaylistToCart from './pages/playlist-to-cart';

// Add the necessary social icons to the library
library.add(fab);

function App() {
  return (
    <BrowserRouter>
      <Helmet defaultTitle="RickyRombo - DJ. Producer. Artist." titleTemplate="%s | RickyRombo" />
      <div className={appStyles.wrapper}>
          <Route path={'/'} children={({ match, location }) => (
            <div className={appStyles.main + ' ' + (match.isExact ? appStyles.mainCenter : appStyles.mainTop)}>
              <Header />
              <div className={appStyles.content}>
                <Switch location={location}>
                  <Route exact path="/" render={() => ''
                  } />
                  <Route path="/about" component={About} />
                  <Route path="/contact" component={Contact} />
                  <Route path="/events" component={Events} />
                  <Route path="/music" component={Music} />
                  <Route path="/playlist-to-cart" component={PlaylistToCart} />
                  <Route component={NotFound} />
                </Switch>
                <Background zoom={match.isExact} shake={match.isExact}  fade={!match.isExact} location={location} />
              </div>
              <Footer />
            </div>
          )} />
        </div>
      </BrowserRouter>
  );
}

export default App;
