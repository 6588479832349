import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

export default function Events() {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      const a = document.createElement('a');
      a.href = "https://www.songkick.com/artists/9809949";
      a.className = "songkick-widget";
      a.dataset.theme = "dark";
      a.dataset.trackButton = "on";
      a.dataset.detectStyle = "true";
      a.dataset.backgroundColor = "transparent";
      a.textContent = "";
      
      const script = document.createElement('script');
      script.src = '//widget.songkick.com/9809949/widget.js';

      const songkickWidget = document.getElementById("songkick");
      songkickWidget.innerHTML = '';
      songkickWidget.appendChild(a);
      songkickWidget.appendChild(script);
    }
  });

  return (
    <article>
      <Helmet><title>Events</title></Helmet>
      <h2>Events</h2>
      <div id="songkick"></div>
    </article>
  )
}