import React from 'react'
import { Helmet } from 'react-helmet'
import SocialsList from '../components/SocialsList'

const socials = [
  'https://play.google.com/store/music/artist?id=A72oxrn62z6beljzrbpmjewaliy',
  'https://itunes.apple.com/us/artist/rickyrombo/1142470496',
  'https://geo.itunes.apple.com/us/artist/rickyrombo/1142470496?app=itunes',
  'https://open.spotify.com/artist/0hPL6sgiUJLBgZgBhi60yJ',
  'https://soundcloud.com/rickyrombo',
  'https://www.amazon.com/s?k=RickyRombo&i=digital-music&search-type=ss&ref=ntt_srch_drd_B01K4GSOJS'
]

export default () => {
  return (
    <article>
      <Helmet><title>Events</title></Helmet>
      <h2>Music</h2>
      <SocialsList socials={socials} action="view" subject="RickyRombo" iconOptions={{ size: 'lg' }}/>
      <p>Originals on all platforms. Remixes on <a title="RickyRombo's Soundcloud Channel" href="https://soundcloud.com/rickyrombo" target="_blank" rel="noopener noreferrer">Soundcloud</a> or <a title="RickyRombo's YouTube Channel" href="https://youtube.com/rickyrombo" target="_blank" rel="noopener noreferrer">YouTube</a></p>
      <iframe title="Soundcloud" width="100%" height="450" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/765342432&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
    </article>
  )
}
