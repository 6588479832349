import React from 'react'
import { Helmet } from 'react-helmet'
import Slideshow from '../components/Slideshow'

// Images
import happy from '../img/slides/happy.jpg'
import sunglasses from '../img/slides/sunglasses.jpg'
import huan from '../img/slides/huan.jpg'
import twist from '../img/slides/twist.jpg'
import frontOfHouse from '../img/slides/front-of-house.jpg'
import point from '../img/slides/point.jpg'

const slides = [
  { image: happy, title: "Photo credit: Ali Vira" },
  { image: sunglasses, title: "Photo credit: Brooke Cheng" },
  { image: twist, title: "Photo credit: Brooke Cheng" },
  { image: huan, title: "Photo credit: Brooke Cheng" },
  { image: frontOfHouse, title: "Photo credit: Brooke Cheng" },
  { image: point, title: "Photo credit: Brooke Cheng" }
]

export default function About() {
  return (
    <article>
      <Helmet><title>About</title></Helmet>
      <h2>About</h2>
      <Slideshow slides={slides} />
      <p>RickyRombo is an electronic music producer from Minnesota. You may have seen him perform on rooftops, in warehouses, on the water, or on train cars in and around the Seattle, Washington area.</p>
      <p>His production style yearns for that organic feel of soulful strings, pianos, and horns while blending the synthetic electronic elements of dance and indietronica.</p>
    </article>
  )
}