import React from 'react'
import SocialsList from '../components/SocialsList'

import footerStyles from './Footer.module.css'

const socials = [
  {
    url: "https://open.spotify.com/artist/0hPL6sgiUJLBgZgBhi60yJ?si=SiRZALkvS7uUvf8SGjOcww",
    subject: 'RickyRombo',
    icon: 'spotify'
  },
  { url: 'https://soundcloud.com/rickyrombo', icon: 'soundcloud' },
  { url: 'https://instagram.com/itsrickyrombo', icon: 'instagram' },
  { url: 'https://facebook.com/itsrickyrombo', icon: 'facebook' },
  { url: 'https://youtube.com/rickyrombo', icon: 'youtube' }
]

export default () => {
  return (
    <footer className={footerStyles.footer}>
      <SocialsList socials={socials} />
      <p className={footerStyles.copyright}>Copyright &copy; {(new Date()).getFullYear()} RickyRombo</p>
    </footer>
  )
}