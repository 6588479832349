import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useInterval } from '../utils/customHooks.js'

import slideStyles from './Slideshow.module.css';
import fade from './fade.module.css';

const Slide = ({image, on, title}) => {
  return (
    <CSSTransition in={on} timeout={1000} classNames={{...fade}}>
      <div title={title} className={slideStyles.slide} style={{backgroundImage: `url(${image})`}}></div>
    </CSSTransition>)
}

export default function Slideshow({slides, options}) {
  let container = React.createRef();
  options = {
    interval: 4000,
    height: '500px',
    ...options
  }
  const [currentImage, setCurrentImage] = useState(0);

  useInterval(() => {
    setCurrentImage((currentImage + 1) % slides.length)
  }, options.interval, currentImage)

  const handleOnClick = (e) => {
    if (e.nativeEvent.offsetX > container.current.clientWidth / 2) {
      setCurrentImage((currentImage + 1) % slides.length)
    } else {
      setCurrentImage((currentImage - 1 + slides.length) % slides.length)
    }
  }

  return (
    <div ref={container} onClick={handleOnClick} className={slideStyles.slidesContainer} style={{height: options.height}}>
      {slides.map((slide, i) => (
        <Slide key={i} image={slide.image} title={slide.title} on={i === currentImage} />
      ))}
    </div>
    )
}