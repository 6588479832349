import React from 'react';
import { CSSTransition } from 'react-transition-group'

import styles from './Background.module.css';
import fadeStyles from './transitions/fadeIn.module.css';
import zoomStyles from './transitions/zoomIn.module.css';

import home from '../img/bg/home.jpg';
import music from '../img/bg/headphones.jpg';
import about from '../img/bg/foh-pink.jpg';
import events from '../img/bg/sunglasses.jpg';
import contact from '../img/bg/pointing-at-amanda.jpg';

const routeMap = {
  music,
  about,
  contact,
  events,
  default: home,
}

export default function Background({shake, zoom, fade, location, image}) {
  const path = location.pathname.substr(1) === '' ? 'default' : location.pathname.substr(1);
  if (location && typeof location.pathname == 'string') {
    image = routeMap[location.pathname.substr(1)];
  }
  if (!image) {
    image = routeMap.default;
  }
  let transitionStyles = '';
  let transitionContainer = '';
  if (zoom) {
    transitionStyles = zoomStyles;
    transitionContainer = styles.zoomContainer;
  }
  if (fade) {
    transitionStyles = fadeStyles;
    transitionContainer = styles.fadeContainer;
  }
  let effects = styles.effectsContainer;
  if (shake) {
    effects += ' ' + styles.shakeContainer;
  }
  return (
    <div>
      {Object.keys(routeMap).map((page) => (
        <CSSTransition key={page} in={page === path} classNames={{...transitionStyles}} timeout={1000} appear={true}>
        <div className={styles.backgroundContainer + ' ' + transitionContainer + ' ' + ((page === path) ? 'on' : 'off')}>
          <div className={styles.transitionsContainer}>
            <div className={effects} style={{backgroundImage: `url(${routeMap[page]})`}}/>
          </div>
        </div>
        </CSSTransition>
      ))}
    </div>
  )
}