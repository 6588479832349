import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import socialListStyles from './SocialsList.module.css'

const serviceNiceName = {
  youtube: 'YouTube',
  google: 'Google Play Music',
  amazon: 'Amazon Music',
  "itunes-note": 'iTunes',
  apple: 'Apple Music'
}
const followVerbs = {
  facebook: 'Like',
  youtube: 'Subscribe to',
  default: 'Follow'
}
const viewVerbs = {
  youtube: 'Watch',
  soundcloud: 'Listen to',
  spotify: 'Listen to',
  google: 'Listen to',
  apple: 'Listen to',
  default: 'View'
}
const getServiceNiceName = (social) => {
  return serviceNiceName[social.icon] ? serviceNiceName[social.icon] : social.icon.charAt(0).toUpperCase() + social.icon.slice(1);
}
const getDefaultTitle = (social, action, subject) => {
  const service = getServiceNiceName(social);
  let verb = action;
  if (action === 'view') {
    verb = viewVerbs[social.icon] ? viewVerbs[social.icon] : viewVerbs.default;
  } else {
    verb = followVerbs[social.icon] ? followVerbs[social.icon] : followVerbs.default;
  }
  if (!social.subject) {
    if (!subject) {
      social.subject = social.url.split('/').pop();
      if (action === 'follow') {
        social.subject = '@' + social.subject;
      }
    } else {
      social.subject = subject;
    }
  }
  return [verb, social.subject, 'on', service].join(' ');
}

export default ({showText, socials, action, subject, iconOptions}) => {
  return (
    <ul className={showText ? 'fa-ul' : socialListStyles['socials-list']}>
      {socials.map(social => {
        if (typeof social === 'string') {
          social = { url: social }
        }
        if (!social.icon) {
          let a = document.createElement('a');
          a.href = social.url;
          const split = a.hostname.split('.');
          social.icon = split[split.length - 2];
          if (social.icon === 'apple' && social.url.indexOf('app=itunes') > 0) {
            social.icon = 'itunes-note';
          }
        }
        return (
          <li key={social.icon}>
            {showText ? <FontAwesomeIcon icon={['fab', social.icon]} {...iconOptions} /> : null}
            <a href={social.url} title={social.title ? social.title : getDefaultTitle(social, action, subject)} target="_blank" rel="noopener noreferrer">
              {showText ? getServiceNiceName(social) : <FontAwesomeIcon icon={['fab', social.icon]} {...iconOptions} />}
            </a>
          </li>
        )
      })}
    </ul>
  )
}